<template>
  <div v-if="props.search">
    <div class="search_div">
      <i class="far fa-search" />
      <input
        :id="'search_' + (props.ref || Math.ceil(Math.random() * 10))"
        v-model="searchText"
        class="filter"
        type="text"
        placeholder="Cerca"
        name="search"
        @input="onFilterTextBoxChanged()"
      />
    </div>
  </div>
  <ag-grid-vue
    v-if="columnDefs"
    :ref="tableRef"
    class="ag-theme-material"
    :gridOptions="gridOptions"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :cacheQuickFilter="true"
    @first-data-rendered="onFirstDataRendered"
  />
</template>
<script setup>
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { onMounted, toRefs, watch, onBeforeMount } from "vue";
import _ from "lodash/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

let props = defineProps({
  initData: [Object, Array],
  columnDefs: [Object, Array],
  showFilters: {
    type: Boolean,
    default: false,
  },
  sortable: {
    type: Boolean,
    default: false,
  },
  search: {
    type: Boolean,
    default: true,
  },
  colsForm: {
    type: String,
    default: "",
  },
  editUrl: {
    type: String,
    default: "#",
  },
  tableName: {
    type: String,
    default: () => "",
  },
  selected: {
    type: Array,
    default: () => [],
  },
  class: {
    type: String,
    default: () => "",
  },
});
const emit = defineEmits(["selectionChanged"]);
let { columnDefs } = toRefs(props);
const gridApi = ref(null);
const gridColumnApi = ref(null);
const rowData = ref(null);
let selected =
  typeof props.selected == "string"
    ? JSON.parse(props.selected)
    : props.selected;
const searchText = ref(null);
const tableRef = ref(null);
const gridOptions = ref({
  defaultColDef: {
    sortable: props.sortable,
    filter: props.showFilters,
    autoHeight: true,
  },
  autoSizeAllColumns: true,
  domLayout: "autoHeight",
  pagination: true,
  suppressRowDeselection: false,
  paginationPageSize: 10,
  suppressCellFocus: true,
  rowStyle: {},
  onSelectionChanged: (params) => {
    const selectedData = params.api.getSelectedRows();
    emit("selectionChanged", selectedData);
  },
  
});
const onFirstDataRendered = (params) => {
  gridApi.value = params.api;
  gridColumnApi.value = params.columnApi;
  params.columnApi.autoSizeAllColumns(false);

  if (selected && selected.length) {
    gridOptions.value.api.forEachNode((node) => {
      node.setSelected(selected.includes(node.data.ID));
    });
  }
  window.addEventListener("resize", function () {
    setTimeout(function () {
      params.columnApi.autoSizeAllColumns(false);
    });
  });
}

if (props.search) {
  gridOptions.value.cacheQuickFilter = true;
}
if (props.class.includes("table_click")) {
  gridOptions.value.suppressRowDeselection = false;
  // getRowSel(gridOptions);
} else {
  gridOptions.value.suppressRowDeselection = true;
}

watch(
  () => props.initData,
  (newVal) => {
    gridOptions.value.api.setRowData(newVal);
  }
),
  onMounted(async () => {
    tableRef.value = "table_" + Math.floor(Math.random() * 10);
    if(props.selected && props.selected.length){
      rowData.value = props.initData ? _.map(props.initData,v=>{
        const Selected=props.selected.includes(v.ID) ? 1 : 0;
        return {...v,Selected};
      },[]) : [];
    }else{
      rowData.value = props.initData||[]
    }
   
  });
onBeforeMount(() => {
  let opts = _.reduce(
    props.class.split(" "),
    (f, v) => {
      switch (v) {
        case "table_click":
          columnDefs.value = _.map(columnDefs.value, (v) => {
            if (v.field == "Selected"){
              v.checkboxSelection = true;
              v.headerCheckboxSelection= true;
              v.cellClass = "hide_cell_value";
              v.headerName= '';
              v.sort='desc'
            }
            return v;
          });
          f.rowSelection = "single";
          f.suppressRowDeselection = false;
          f.suppressRowClickDeselection = false;
          break;
        case "multiple":
          f.rowSelection = "multiple";
          f.rowMultiSelectWithClick = true;
          break;
        case "resize":
          f.resizable = true;
          break;
        case "no_sel":
          f.suppressRowDeselection = true;
          f.suppressRowClickDeselection = true;
          break;
        default:
          f.suppressRowDeselection = false;
          columnDefs.value = _.map(columnDefs.value, (v) => {
            if (v.field == "Selected"){
              v.checkboxSelection = true;
              v.headerCheckboxSelection= true;
            }
            return v;
          });
          break;
      }
      return f;
    },
    {}
  );
  gridOptions.value = { ...gridOptions.value, ...opts };
});
const onFilterTextBoxChanged = () => {
  gridApi.value.setQuickFilter(searchText.value);
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
div.search_div {
  flex: 10;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border: 1px solid $irideos-blue;
  @include BorderRadius($all: 5px);
  &:focus,
  &:active {
    border: 2px solid $irideos-blue;
    outline: none !important;
  }
  i {
    color: $irideos-blue;
    margin-right: 10px;
  }
  input {
    border: none !important;
    outline: none !important;
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    background-color: transparent;
    width: 100%;
    height: 45px;
    & > input:after {
      color: $irideos-blue;
    }
  }
}
</style>
