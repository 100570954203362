<template>
  <div class="cont_modal">
    <div class="view_modal">
      <div class="title_view">
        <button v-on:click="$emit('close-modal')" class="close_modal btn"></button>
        <slot name="title"></slot>
      </div>
      <div class="body_view">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  head: {
    bodyAttrs: {
      class: 'overflow'
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/css/variables.scss';
.cont_modal{
  width: 100%;
  height: 100%;
  position: fixed;
  padding: 50px 0;
  top: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  z-index: 100;
  background: rgba(0,0,0,0.5);
  &::before{
    content: "";
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
  }
  & > *{
    display: inline-block;
  }
  .view_modal{
    display: inline-block;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    @include BorderRadius($all:5px);
    opacity: 1;
    width: 80%;
    position: relative;
    z-index: 2;
    top: 0;
    text-align: left;
    vertical-align: middle;
    background: #FFF;
    padding: 20px;
    will-change:top;
    min-height: 50px;
    cursor: default;
    .title_view{
      margin-bottom: 10px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .close_modal{

      right: 10px;
      text-align: right;
      top: 10px;
      color: #000;
      text-decoration: none;
      border: none;
      z-index: 5;
      cursor: pointer;
      background:none;
      &::before{
        color: #000;
        font-family: "Font Awesome 5 Pro";
        font-size: 30px;
        font-weight: 300;
        content: "\f00d";
        display: block;
      }
    }
  }

}




</style>