import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import axios from "axios";
import moment from "moment";
import Maska from "maska";
// import alert from "@/plugins/alert";

const app = createApp(App);

app.config.devtools = true;
app.use(createPinia());
app.use(router);
app.use(Maska);
moment.locale("it", {
  months: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
});
app.mount("#app");
app.config.globalProperties.axios=null;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
