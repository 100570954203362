import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  base: '',
  routes: [
    {
      path: '/',
      name: 'home',
      showInMenu: true,
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/publics',
      name: 'Public',
      showInMenu: true,
      component: () => import('@/views/DocumentsPublic.vue')
    },
    {
      path: '/publics/:documentID',
      name: 'publicDetail',
      showInMenu: false,
      props: route => ({ documentID: route.params.id, r: route.query.r }),
      component: () => import('@/views/DocumentsDetail.vue')
    },
    {
      path: '/approved',
      name: 'Approved',
      query: { rev: 0 },
      showInMenu: true,
      component: () => import('@/views/DocumentsPublic.vue')
    },
    {
      path: '/approved/:documentID',
      name: 'approvedDetail',
      showInMenu: false,
      props: route => ({ documentID: route.params.id, r: route.query.r }),
      component: () => import('@/views/DocumentsDetail.vue')
    },
    {
      path: '/history',
      name: 'History',
      showInMenu: true,
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/editor',
      name: 'editor',
      showInMenu: true,
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/validation',
      name: 'validation',
      showInMenu: true,
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/documents/:documentID',
      name: 'documents',
      showInMenu: false,
      component: () => import('@/views/DocumentsDetail.vue')
    },
    {
      path: '/login',
      name: 'Login',
      showInMenu: false,
      component: () => import('@/views/Login.vue')
    },
    {
      path: '/profile',
      name: 'Profile',
      inMenu: false,
      component: () => import('@/views/Profile.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      inMenu: false,
      component: () => import('@/views/Logout.vue')
    },
    {
      path: '/testapi',
      name: 'testapi',
      inMenu: false,
      component: () => import('@/views/Testapi.vue')
    },
  ],
});
export default router;
