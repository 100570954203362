import { defineStore } from "pinia";
import axios from "axios";
import _ from "lodash";
export const useUserStore = defineStore("main", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("auth")) || {},
    validatorsRole: ['','Redazione','Verifica','Approvazione'],
  }),
  getters: {
    fullName: (state) => state.user.name + " " + state.user.surname,
  },
  actions: {
    setUser(userData) {
      // userData.readOnly = parseInt(userData.readOnly);
      if(userData.Liv) userData.Liv = parseInt(userData.Liv);
      userData.Read_Only = !!parseInt(userData.Read_Only);
      localStorage.setItem("auth", JSON.stringify(userData));
      delete(userData.success);
      this.user = userData;
    },
    getValidatorIndex(role) {
      return _.findIndex(this.validatorsRole, (o) => o === role);
    },
    async isLogged()  {
      if(!Object.keys(this.user).length) return {success: false};
      let res = await axios.post("/is_logged/?token=" + this.user.token);
      return res.data;
    },
  },
});